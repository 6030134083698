import React, { useState, useEffect } from "react";
import { doHttpGet } from "../../services/WebService";
import { healthcareInfoUrl } from "../../library/Urls";
import Money from "../common/Money";
import { Tabs, Tab } from "react-bootstrap";
import FinancialAccounts from "./FinancialAccounts";

const Healthcare = () => {
    // State
    const [healthcareInfo, setHealthcareInfo] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    // Get healthcare info
    const getHealthcareInfo = (p) => {
        // Params
        const params = {};
        if (p) {
            params.inclDisabled = p.inclDisabled;
        }
        // GET
        doHttpGet({
            url: healthcareInfoUrl,
            params: params,
            onSuccess: (info) => {
                setErrorMessage(null);
                setHealthcareInfo(info);
            },
            onError: (error) => setErrorMessage(error),
        });
    };

    // Changes
    useEffect(() => getHealthcareInfo(), []);

    // Render
    return (
        <div className="page-content">
            {errorMessage && (
                <div
                    className="alert alert-danger"
                    style={{
                        marginBottom: "10px",
                        fontWeight: "bold",
                        fontSize: "1.2em",
                        padding: "10px",
                    }}
                >
                    {errorMessage}
                </div>
            )}

            {healthcareInfo && (
                <div>
                    {!healthcareInfo.featureExists && (
                        <div className="alert alert-danger" style={{marginBottom: "5px"}}>
                            The <b>Healthcare</b> feature has not been added to this account
                        </div>
                    )}
                    {!healthcareInfo.featureEnabled && (
                        <div className="alert alert-danger" style={{marginBottom: "5px"}}>
                            The <b>Healthcare</b> feature is not enabled for this account
                        </div>
                    )}
                    <div className="row">
                        <div className="col-8">
                            <h2>
                                Healthcare
                                <span className="gzn-detail-flag">Feature/Option</span>
                            </h2>
                        </div>
                        <div
                            className="col-4"
                            style={{textAlign: "right", verticalAlign: "bottom"}}
                        >
                            <Money amount={healthcareInfo.totalDesignatedAmount} className="amount-lg"/>
                        </div>
                    </div>
                    <Tabs defaultActiveKey="fa" id="financial-account-tab" style={{marginTop: "10px"}}>
                        <Tab
                            eventKey="fa"
                            title={
                                "Financial Accounts (" + healthcareInfo.financialAccountCount + ")"
                            }
                        >
                            <FinancialAccounts
                                financialAccounts={healthcareInfo.financialAccounts}
                                financialAccountBalance={healthcareInfo.totalFinancialAccountBalance}
                                includeDisabledAccounts={healthcareInfo.includeDisabledAccounts}
                                refreshHealthcareInfo={(params) => getHealthcareInfo(params)}
                                showErrorMessage={(errorMsg) => setErrorMessage(errorMsg)}
                            />
                        </Tab>
                    </Tabs>
                </div>
            )}
        </div>
    );
};

export default Healthcare;