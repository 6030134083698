/**
 * Whether a value is defined.
 * @param {*} v Value.
 */
export const isDefined = (v) => {
  return v != null && typeof v !== "undefined";
};

/**
 * Format number as USD.
 * @param {*} amount
 */
export const fmtMoneyUSD = (amount, showSymbol, plusMinus) => {
  if (amount !== undefined) {
    if (showSymbol === undefined) showSymbol = true;
    if (showSymbol) {
      const _amt = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(amount);
      return (plusMinus && amount > 0.0 ? "+" : "") + _amt;
    } else {
      return fmtNumber(amount, 2);
    }
  }
  return "";
};

const localTimeOffset = () => {
  const dt = new Date();
  const sign = dt.getTimezoneOffset() > 0 ? "-" : "+";
  const offset = Math.abs(dt.getTimezoneOffset());
  let hours = Math.floor(offset / 60);
  if (hours < 10) {
    hours = "0" + hours;
  }
  let minutes = offset % 60;
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  return "T04:00:00" + sign + hours + ":" + minutes;
};

/**
 * Format date, adjusted for time zone
 * @param {*} date SQL-format date.
 */
export const fmtDate = (date) => {
  if (date) {
    if (date.match(/^\d{4}-\d{2}-\d{2}$/)) {
      date += localTimeOffset();
    }
    const d = new Date(date);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    }).format(d);
  }
  return "";
};

export const asDateTime = (date) => {
  const dt = fmtDate(date);
  return dt ? new Date(dt) : null;
};

/**
 * Finds the array index of an object with the given attribute value.
 * Returns -1 if none is found.
 * @param {*} array Array to search.
 * @param {*} attr Attribute name in objects.
 * @param {*} value Value of the attribute.
 */
export const findIndexWithAttr = (array, attr, value) => {
  const itemValue = value.toString();
  for (var i = 0; i < array.length; i += 1) {
    const attrValue = array[i][attr].toString();
    if (attrValue === itemValue) {
      return i;
    }
  }
  return -1;
};

export const findItemByAttribute = (array, attr, value) => {
  if (array == null || value == null) {
    return null;
  }
  const index = findIndexWithAttr(array, attr, value);
  return index < 0 ? null : array[index];
};

export const removeIndexWithAttr = (array, attrName, attrValue) => {
  if (array == null) {
    return null;
  }
  const index = findIndexWithAttr(array, attrName, attrValue);
  if (index >= 0) {
    const item = array[index];
    array.splice(index, 1);
    return item;
  }
  return null;
};

export const isValidUsMoney = (value) => {
  return /^[-+]{0,1}\${0,1}(\d+,)*\d*(\.\d+){0,1}$/i.test(value);
};

export const toUsMoney = (value) => {
  if (value) {
    const cleaned = (value + "").replace(/[,+\\$]/g, "");
    const amt = parseFloat(cleaned);
    return Number(amt);
  } else {
    return null;
  }
};

export const fmtInterestRate = (rate) => {
  if (rate !== undefined) {
    return (
      new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 3,
      }).format(rate) + "%"
    );
  }
  return "";
};

export const fmtNumeric = (num) => {
  if (num !== undefined) {
    return new Intl.NumberFormat("en-US", {
      style: "decimal",
      maximumFractionDigits: 0,
    }).format(num);
  }
  return "";
};

export const fmtNumber = (num, prec) => {
  if (num == null) return "";
  if (num === undefined) prec = 0;
  if (num !== undefined) {
    return new Intl.NumberFormat("en-US", {
      style: "decimal",
      maximumFractionDigits: prec,
      minimumFractionDigits: prec,
    }).format(num);
  }
  return "";
};

export const countOrZero = (collection) => {
  if (collection === undefined) return 0;
  return collection.length;
};

export const currentYearMonth = () => {
  var d = new Date();
  const ye = new Intl.DateTimeFormat("en-US", { year: "numeric" }).format(d);
  const mo = new Intl.DateTimeFormat("en-US", { month: "2-digit" }).format(d);
  return parseInt(`${ye}${mo}`, 10);
};

export const fullYearMonthName = (ym) => {
  if (ym) {
    const d = (ym + "").replace(/(\d{4})(\d{2})/, "$1-$2-01");
    const date = new Date(d + localTimeOffset());
    const year = new Intl.DateTimeFormat("en-US", { year: "numeric" }).format(
      date
    );
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    return `${month} ${year}`;
  }
  return ym;
};

export const ymMonth = (ym) => {
  if (ym) {
    const yr = (ym + "").replace(/(\d{4})(\d{2})/, "$2");
    return parseInt(yr, 10);
  }
  return null;
};

export const ymYear = (ym) => {
  if (ym) {
    const yr = (ym + "").replace(/(\d{4})(\d{2})/, "$1");
    return parseInt(yr, 10);
  }
  return null;
};

export const currentYear = () => {
  const d = new Date();
  return new Intl.DateTimeFormat("en-US", { year: "numeric" }).format(d);
};

export const pluralize = (count, word) => {
  return pluralSuggestion(count, word, null);
};

export const pluralSuggestion = (count, word, sugg) => {
  const _plural = sugg ? sugg : word + "s";
  return count + " " + (count === 1 ? word : _plural);
};

export const UUID = () => {
  // Get now time
  const n = Date.now();
  // Generate random
  const r = Math.random();
  // Stringify now time and generate additional random number
  const s = String(n) + String(~~(r * 9e4) + 1e4);
  // Form UUID and return it
  return `${s.slice(0, 8)}-${s.slice(8, 12)}-4${s.slice(12, 15)}-${
    [8, 9, "a", "b"][~~(r * 3)]
  }${s.slice(15, 18)}-${s.slice(s.length - 12)}`;
};

export const isFloat = (num) => {
  const _num = parseFloat(num);
  return !isNaN(_num);
};

export const toFloat = (target, defaultValue) => {
  if (isFloat(target)) {
    return parseFloat(target);
  }
  return defaultValue;
};

export const removeFromArray = (array, item) => {
  const index = indexInArray(array, item);
  if (index >= 0) {
    array.splice(index, 1);
  }
};

export const indexInArray = (array, item) => {
  let index = -1;
  for (var i = 0; i < array.length; i++) {
    if (array[i] === item) {
      index = i;
      break;
    }
  }
  return index;
};

export const fmtDateSimple = (date) => {
  if (date) {
    if (date.match(/^\d{4}-\d{2}-\d{2}$/)) {
      date = new Date(date + localTimeOffset());
    }
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    }).format(date);
  }
  return "";
};

export const fmtDateFullMonth = (date) => {
  if (date) {
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    const year = new Intl.DateTimeFormat("en-US", { year: "numeric" }).format(
      date
    );
    return `${month} ${year}`;
  }
  return "";
};

export const timestamp = () => {
  return new Date().getTime();
};

export const rewardStatement = (amount, rewardType) => {
  if (rewardType === "Points") {
    return fmtNumber(amount) + " Points";
  } else {
    return fmtMoneyUSD(amount) + " Cash";
  }
};

export const isNumber = (value) => {
  if (value) {
    const amt = parseFloat(value + "");
    return !isNaN(Number(amt));
  }
  return false;
};

export const isUUID = (uuid) => {
  if (uuid) {
    return (uuid + "").match(/\d+(-\d+)+/);
  }
  return false;
};

const RFDC_CLONE = require("rfdc")();
export const clone = (o) => RFDC_CLONE(o);

export const pause = async (seconds) => {
  await new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

export const toLong = (n) => {
  if (n === null) {
    return 0;
  }
  return Math.round(n);
};

export const fmtLong = (n) => {
  if (n === null) {
    return "";
  }
  return toLong(n) + "";
};

export const fmtPercent = (num, denom, prec) => {
  if (num === null || denom === null) {
    return "";
  }
  if (denom === 0.0) {
    return "0%";
  }
  let pct = (num / denom) * 100.0;
  if (pct > 100.0) {
    pct = 100.0;
  }
  return fmtNumber(pct, prec) + "%";
};

export const forPlural = (n, s, p) => {
  return n === 1 ? s : p;
};

export const numberToFixedOrZero = (n, f) => {
  return n === null ? "0.00" : n.toFixed(f);
};
